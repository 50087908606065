<script>
  export let lady = '../assets/images/home/girl.webp';
  export let pattern1 =
    '../assets/images/home/Group-1992-1.webp';
  export let pattern2 = '../assets/images/home/01-1.webp';

  let activeTab = 0;

  let useCases = [
    {
      title: 'E-Commerce',
      content: `Integrate Qflit with platforms like WooCommerce and Shopify to enhance customer experiences. Build personalized shopping assistants that handle inquiries, recommend products, and facilitate purchases. Utilize Qflit’s API to send customer messages to your AI assistant effortlessly, and receive dynamic responses via WebSockets and webhooks.`,
    },
    {
      title: 'Appointments',
      content: `Streamline appointment scheduling with Qflit by creating assistants that allow users to book appointments easily. Whether for healthcare, beauty services, or consultations, your assistant can manage availability and handle cancellations, ensuring a smooth user experience.`,
    },

    {
      title: 'Data Management',
      content: `Automate workflows and data management tasks using platforms like Airtable through Qflit. Create assistants that retrieve and update data effortlessly, ensuring records are accurate and up-to-date.`,
    },
    {
      title: 'UI Design',
      content: `Leverage Qflit to create assistants that generate live UI elements dynamically. Build interactive interfaces that adapt based on user interactions, providing real-time updates and enhancing engagement.`,
    },
  ];

  const selectTab = (index) => {
    activeTab = index;
  };
</script>

<div id="use-cases"></div>
<div class="parent-container">
  <div class="text-column">
    <div class="imgs">
      <img src="{lady}" alt="" class="img-girl" />
      <img src="{pattern1}" alt="" class="img-pattern1" />
      <img src="{pattern2}" alt="" class="img-pattern2" />
    </div>
    <div class="col">
      <h1>Transformative <br />Use Cases</h1>
      <p class="label">
        With Qflit, you can create AI-powered assistants
        tailored to your unique business needs. Here are a
        few examples.
      </p>

      <div class="tabs">
        {#each useCases as { title }, index}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="tab {activeTab === index
              ? 'active'
              : ''}"
            on:click="{() => selectTab(index)}"
          >
            <p>{title}</p>
          </div>
        {/each}
      </div>

      <!-- Tab Content -->
      {#each useCases as { content }, index}
        {#if activeTab === index}
          <div class="tab-content">
            <p>{content}</p>
          </div>
        {/if}
      {/each}
    </div>
  </div>
</div>

<style>
  .imgs {
    display: flex;
    position: relative;
    align-self: stretch;
    flex: 1;
    max-width: 520px;
  }

  .img-girl {
    object-fit: contain;
    width: auto;
    height: 38vw;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
  }

  .img-pattern1 {
    object-fit: contain;
    width: auto;
    height: 250px;
    position: absolute;
    bottom: 0px;
    left: 200px;
    z-index: 2;
  }

  .img-pattern2 {
    object-fit: contain;
    width: auto;
    height: 200px;
    position: absolute;
    top: 60px;
    left: 10px;
    z-index: 1;
  }

  h1 {
    font-size: 48px;
    margin: 0px;
    margin-top: 16px;
  }
  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 36px;
    padding-bottom: 48px;
    gap: 16px;
    max-width: 520px;
  }

  .label {
    font-weight: 300;
  }

  .parent-container {
    width: calc(100% - 20px);
    max-width: 1200px;
    margin: auto;
    overflow-x: hidden;
    z-index: 3;
    padding-left: 3rem;
    padding-right: 3rem;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: center;
    background-color: #4939ff;
    color: white;
    position: relative;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    margin: 0px;
  }

  .text-column {
    text-align: left;
    flex-direction: row;
    display: flex;
    width: 100%;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.5s ease;
    border-radius: 32px;
    background-color: hsla(240, 100%, 73%, 0.361);
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .tab:hover {
    color: aqua;
  }

  .tab.active {
    background-color: white;
    color: #0073e6;
  }

  .tab-content {
    padding: 8px;
    height: 240px;
    transition: all 0.3s ease;
    background-color: rgba(9, 101, 240, 0.506);
    color: rgb(238, 235, 255);
    border-radius: 16px;
    padding: 24px;
    box-sizing: border-box;
  }

  @media (max-width: 768px) {
    .parent-container {
      padding: 1.5rem;
      padding-bottom: 0rem;
      margin-bottom: 0rem;
      margin-top: 0px;
      text-align: center;
      background-color: rgb(254, 255, 246);
      align-items: center;
      color: black;
    }

    .tab:hover {
      color: chocolate;
    }

    .tab {
      background-color: white;
      color: #005719;
    }

    .tab p {
      font-size: 14px;
    }

    .tab-content {
      height: auto;
    }
    .tab-content p {
      font-size: 15px;
    }
    .tab.active {
      background: #00781a;
      color: white;
    }

    .tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
    }

    .label {
      text-align: center;
      font-size: 16px;
    }

    .tab-content {
      background-color: white;
      color: black;
    }
    .imgs {
      display: none;
    }

    .img-girl {
      position: relative;
      top: auto;
      left: auto;
    }

    h1 {
      text-align: center;
      font-size: 25px;
    }

    .col {
      padding-top: 0px;
    }

    .img-pattern2 {
      display: none;
    }
  }
</style>
