<script>
  import Button from './Button.svelte';
  export let man = '../assets/images/home/man.webp';

  export let girl =
    '../assets/images/home/deal-tracking.webp';

  function openWhatsapp() {
    window.location.href =
      'https://docs.qflit.com/whatsapp';
  }
</script>

<div id="whatsapp"></div>
<div class="whatsapp-container-desktop">
  <div>
    <div class="text-column">
      <div class="col">
        <div class="text-img-row"></div>
        <div class="header-desktop">
          <h1>
            Delight Your Customers with AI Assistants on
            <span style="color:#00B741"> WhatsApp</span>
          </h1>
        </div>
        <p>
          With Qflit, you can easily launch no-code AI
          assistants on WhatsApp, ensuring exceptional
          customer experiences with minimal effort. Simply
          provide a phone number and instructions for your
          assistant to get started — it's that easy! <br
          /><br />Deliver novel experiences that delight
          customers, like conversational shopping,
          empowering them to interact seamlessly through
          WhatsApp.
        </p>
        <div class="btn-learn-more">
          <Button
            onClick="{() => openWhatsapp()}"
            verticalPadding="20px"
            label="Learn More"
            horizontalPadding="24px"
            textColor="white"
            backgroundColor="#001d0d"
          ></Button>
        </div>
      </div>
      <img src="{man}" alt="" class="man" />
    </div>
  </div>
</div>

<div class="whatsapp-container-mobile">
  <div class="header-mobile">
    <h1>
      Delight Your Customers with AI Assistants on
      <span style="color:#00B741"> WhatsApp</span>
    </h1>
    <img src="{girl}" alt="" class="girl" />
    <p>
      With Qflit, you can easily launch no-code AI
      assistants on WhatsApp, ensuring exceptional customer
      experiences with minimal effort. Simply provide a
      phone number and instructions for your assistant to
      get started — it's that easy!
    </p>

    <div class="btn-learn-more">
      <Button
        onClick="{() => openWhatsapp()}"
        verticalPadding="20px"
        label="Learn More"
        horizontalPadding="24px"
        textColor="white"
        backgroundColor="#001d0d"
      ></Button>
    </div>
  </div>
</div>

<style>
  .whatsapp-container-mobile {
    display: none;
  }
  .man {
    object-fit: contain;
    width: 50%;
    height: auto;
  }
  .girl {
    display: none;
  }
  .header-mobile {
    display: none;
  }

  h1 {
    font-size: 48px;
    max-width: 960px;
    margin-top: 16px;
  }
  .col {
    display: flex;
    flex-direction: column;
  }

  .text-img-row {
    display: flex;
    gap: 15px;
  }

  .text-img-row h1 {
    font-size: 24px;
  }
  .header-desktop {
    display: flex;
  }

  .btn-text {
    font-size: 15px;
  }

  .btn-learn-more {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
  }

  .whatsapp-container-desktop {
    width: calc(100% - 20px);
    max-width: 1200px;
    margin: auto;
    overflow-x: hidden;
    z-index: 3;
    padding: 3rem;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    margin-top: 4rem;
    text-align: center;
    background-color: #00ff8018;
    position: relative;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    margin: 0px;
  }

  .text-column {
    text-align: left;
    padding: 18px;
    flex-direction: row;
    display: flex;
    gap: 32px;
  }

  @media (max-width: 768px) {
    .whatsapp-container-desktop {
      display: none;
    }

    .whatsapp-container-mobile {
      display: flex;
      width: calc(100% - 20px);
      margin: auto;
      padding: 1.5rem;
      margin-bottom: 0rem;
      margin-top: 2rem;
      border-radius: 16px;
      border-style: dashed;
      border-color: rgba(221, 221, 221, 0.302);
      box-shadow: 5px 5px 24px 1px rgb(245, 245, 245);
    }

    .header-desktop {
      display: none;
    }

    .header-mobile {
      display: flex;
      flex-direction: column;

      flex: 1;
    }

    .header-mobile h1 {
      font-size: 24px;
      max-width: 360px;
    }

    p {
      font-size: 17px;
    }
    .man {
      display: none;
    }

    .girl {
      display: flex;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
</style>
