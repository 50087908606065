<script>
  export let bannerman =
    '../assets/images/home/banner_2man.webp';
  let name = '';
  let email = '';
  let message = '';
  let formSubmitted = false;
  let loading = false;
  let errorMessage = '';

  const submitForm = async (e) => {
    e.preventDefault();
    formSubmitted = false;
    errorMessage = '';
    loading = true;

    try {
      const response = await fetch(
        'https://api.qflit.com/forms',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ name, email, message }),
        },
      );

      if (!response.ok) {
        throw new Error(
          'There was an issue submitting the form.',
        );
      }

      formSubmitted = true;
    } catch (error) {
      errorMessage =
        'There was an issue submitting the form.';
    } finally {
      loading = false;
    }
  };
</script>

<div id="contact"></div>
<div class="contact-container">
  <div class="content-wrapper">
    <div class="image-column">
      <p class="txt-1">
        Let’s Connect and <b>Build the Future Togethe</b>r
      </p>
      <p class="txt-2">
        Your insights matter! Share your thoughts or
        questions about Qflit AI Studio. We're eager to
        connect and help you unlock the full potential of
        AI!
      </p>
      <img src="{bannerman}" alt="Contact Us" />
    </div>

    <div class="form-column">
      <h1>Leave us a message</h1>
      <p class="form-cta-txt">
        Have any questions, feature suggestions, or issues?
        Send us an email at
        <a href="mailto:hello@qflit.com">hello@qflit.com</a>
        or fill out the form below.
      </p>

      {#if formSubmitted}
        <p class="submitted-message">
          Thank you for your message! We'll get back to you
          soon.
        </p>
      {:else}
        <form on:submit="{submitForm}">
          <div class="input-container">
            <input
              id="name"
              type="text"
              bind:value="{name}"
              required
              placeholder=" "
            />
            <label for="name" class="animated-label"
              >Your Name</label
            >
          </div>

          <div class="input-container">
            <input
              id="email"
              type="email"
              bind:value="{email}"
              required
              placeholder=" "
            />
            <label for="email" class="animated-label"
              >Your Email</label
            >
          </div>

          <div class="input-container">
            <textarea
              id="message"
              bind:value="{message}"
              rows="4"
              required
              placeholder=" "
            ></textarea>
            <label for="message" class="animated-label"
              >Your Message</label
            >
          </div>

          <button type="submit">
            {#if loading}
              Sending <span class="loading"></span>
            {:else}
              Send Message
            {/if}
          </button>

          {#if errorMessage}
            <p class="error-message">{errorMessage}</p>
          {/if}
        </form>
      {/if}
    </div>
  </div>
</div>

<style>
  .contact-container {
    margin: auto;
    width: 100%;
    width: calc(100vw - 20px);
    max-width: 1200px;
    box-sizing: border-box;
  }

  .content-wrapper {
    display: flex;
    gap: 80px;
  }

  .txt-1,
  .txt-2 {
    color: white;
  }

  .txt-1 {
    font-size: 24px;
  }

  .txt-2 {
    font-weight: 300;
  }

  .form-cta-txt {
    margin-bottom: 32px;
    max-width: 500px;
  }

  .image-column {
    flex: 1;
    border-radius: 16px;
    background-color: #245327;
    color: white;
    padding: 2rem;
    padding-bottom: 0px;
    max-width: 450px;
  }

  .image-column img {
    height: 370px;
    border-radius: 16px;
    margin-bottom: -5px;
  }

  a {
    color: #004100;
  }

  .form-column {
    flex: 1;
    max-width: 550px;
  }

  h1 {
    font-size: 2.5rem;
    color: #004100;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .input-container {
    position: relative;
  }

  input,
  textarea {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: 2px solid transparent;
    border-radius: 8px;
    background-color: #f7f7f7db;
    transition: all 0.3s ease;
  }

  input:focus,
  textarea:focus {
    border-color: #00a34f;
    background-color: #ffffff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  }

  .animated-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    background: transparent;
    color: #999;
    font-size: 1rem;
    transition: all 0.2s ease;
  }

  input:not(:placeholder-shown) + .animated-label,
  input:focus + .animated-label,
  textarea:not(:placeholder-shown) + .animated-label,
  textarea:focus + .animated-label {
    top: -12px;
    background-color: rgba(255, 255, 255, 0);
    padding: 0 5px;
    font-size: 0.85rem;
    color: #005210;
  }

  button {
    padding: 18px;
    background: #002316;
    color: white;
    border-radius: 48px;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all ease 0.5s;
    transition: all 0.3s ease;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  button:hover {
    background: #000a07;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }

  button:focus {
    outline: none;
  }

  .loading {
    display: inline-block;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid hsl(143, 100%, 27%);
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-left: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .submitted-message {
    color: #28a745;
    font-size: 1.4rem;
    margin-top: 20px;
    animation: fadeIn 0.5s ease;
  }

  .error-message {
    color: #dc3545;
    font-size: 1.1rem;
    margin-top: 20px;
    animation: fadeIn 0.5s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
    }

    .image-column {
      display: none;
    }

    h1 {
      font-size: 2rem;
      margin-top: 0;
    }

    .form-column {
      max-width: 100%;
    }

    .contact-container {
      padding: 20px;
    }
  }
</style>
