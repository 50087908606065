<script>
  import ToolsIcon from './icons/ToolsIcon.svelte';
  import FlashIcon from './icons/FlashIcon.svelte';
  import IntegrationsIcon from './icons/IntegrationsIcon.svelte';
  import LinkIcon from './icons/LinkIcon.svelte';
</script>

<div id="how-it-works"></div>
<div class="features-container">
  <div class="label">
    <p>How it works</p>
  </div>

  <h1>
    Deploy <span style="color:#00B741">ready-to-use</span>
    AI solutions in minutes
  </h1>

  <div class="steps-parent">
    <div class="column text-column">
      <div>
        <div class="icon-container-desktop">
          <FlashIcon />
        </div>
      </div>
      <div>
        <div class="icon_text_container">
          <h3>Streamlined AI Dev</h3>
          <div class="icon-container-mobile">
            <FlashIcon />
          </div>
        </div>
        <p class="explainer">
          Qflit simplifies AI integration by eliminating the
          need to manage code, threads, or events. With its
          intuitive web dashboard, you can create AI
          assistants, define functions, and connect APIs
          visually. Initiate messages via the messaging
          endpoint, and Qflit will handle the rest.
        </p>
      </div>
    </div>
    <div class="column text-column">
      <div>
        <div class="icon-container-desktop">
          <LinkIcon />
        </div>
      </div>
      <div>
        <div class="icon_text_container">
          <h3>Websockets & Webhooks</h3>
          <div class="icon-container-mobile">
            <LinkIcon />
          </div>
        </div>

        <p class="explainer">
          After you initiate a message through our messaging
          endpoint, Qflit sends events and responses from
          your AI Assistant to your connected websocket
          clients and configured webhooks. This dynamic
          approach ensures you receive real-time updates and
          messages seamlessly.
        </p>
      </div>
    </div>
    <div class="column text-column">
      <div>
        <div class="icon-container-desktop">
          <IntegrationsIcon />
        </div>
      </div>
      <div>
        <div class="icon_text_container">
          <h3>Unlimited Integrations</h3>
          <div class="icon-container-mobile">
            <IntegrationsIcon />
          </div>
        </div>

        <p class="explainer">
          Easily connect to external systems or your own
          APIs using our intuitive visual function
          configuration and endpoint designer. Define
          function names and inputs without any coding
          required. Qflit takes care of the function calls
          and seamlessly delivers the results back to your
          AI assistant.
        </p>
      </div>
    </div>
    <div class="column text-column">
      <div>
        <div class="icon-container-desktop">
          <ToolsIcon />
        </div>
      </div>
      <div>
        <div class="icon_text_container">
          <h3>Code Interpreter and File Search</h3>
          <div class="icon-container-mobile">
            <ToolsIcon />
          </div>
        </div>

        <p class="explainer">
          Qflit enhances your AI assistant's capabilities
          with an integrated code interpreter and robust
          file search functionality. Easily run code
          snippets to manipulate data on the fly, and attach
          files directly with incoming messages.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  .label {
    background-color: rgba(194, 255, 220, 0.251);
    align-self: center;
    border-radius: 32px;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .label p {
    color: #0a5f3a;
    font-size: 15px;
    font-weight: 300;
    margin: 0;
  }

  h1 {
    font-size: 48px;
    max-width: 720px;
    margin-top: 16px;
    text-align: center;
  }
  .icon_text_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    margin: 0px;
  }

  .features-container {
    width: calc(100% - 20px);
    max-width: 1200px;
    margin: auto;
    overflow-x: hidden;
    z-index: 3;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    margin-top: 4rem;
    text-align: center;
    background-color: #fefff5;
    position: relative;
  }

  .steps-parent {
    display: grid;
    gap: 20px;
    text-align: left;
    margin-top: 2rem;
  }

  .column {
    padding: 0 1rem;
    background-color: white;
    border-radius: 16px;
    align-self: stretch;
    flex: 1; /* Set to take up equal space */
  }

  p {
    font-size: 20px;
    line-height: 24px;
    margin: 0px;
  }

  h3 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .icon-container-desktop {
    margin-top: 8px;
    border-radius: 50%;
    height: 50px;
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    box-shadow: 5px 5px 24px 1px rgba(121, 152, 255, 0.106);
  }

  .text-column {
    text-align: left;
    padding: 18px;
    display: flex;
    gap: 8px;
  }

  .icon-container-mobile {
    display: none;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 50%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    align-self: flex-start;
    display: none;

    box-shadow: 5px 5px 24px 1px rgba(121, 152, 255, 0.106);
  }

  @media (max-width: 600px) {
    .features-container {
      width: 100%;
      margin-bottom: 2rem;
      padding: 0rem;
      margin-top: 2rem;
      padding-bottom: 0rem;
      background-color: white;
    }

    .icon_text_container {
      margin-bottom: 8px;
    }

    .icon-container-desktop {
      display: none;
    }

    .icon-container-mobile {
      display: flex;
    }

    .steps-parent {
      display: flex;
      flex-direction: row;
      overflow-x: scroll !important;
      width: 100%;
      gap: 16px;
      box-sizing: border-box;
      padding: 1.5rem;
      padding-top: 0px;
      flex: 1;
    }

    .column {
      min-width: 80vw;
      max-width: 80vw;
      background-color: white;
      box-shadow: 5px 5px 24px 1px rgb(245, 245, 245);
    }

    h1 {
      font-size: 24px;
      margin: 16px;
    }

    h3 {
      font-size: 16px;
    }
    .explainer {
      font-size: 15px;
    }

    .step {
      flex-direction: row;
      display: flex;
    }
  }

  @media (min-width: 701px) and (max-width: 900px) {
    .features-container {
      padding: 1rem;
      margin-bottom: 2rem;
      background-color: white;
    }

    .column {
      box-shadow: 5px 5px 24px 1px rgb(245, 245, 245, 0.5);
    }

    .icon-container-desktop {
      box-shadow: 5px 5px 24px 1px rgba(103, 103, 103, 0.1);
    }

    h1 {
      font-size: 48px;
    }

    @media (min-width: 801px) and (max-width: 1000px) {
      .features-container {
        padding: 2rem;
      }

      .steps-parent {
        grid-template-columns: 1fr;
      }

      .column {
        box-shadow: 5px 5px 24px 1px rgb(245, 245, 245, 0.5);
      }
    }
  }

  @media (min-width: 901px) {
    .steps-parent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
    }
  }
</style>
