<script>
  import { slide } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';

  const faqs = [
    {
      question: 'What is Qflit AI Studio?',
      answer:
        "Qflit AI Studio is a platform that simplifies the creation and management of AI-powered chatbots using OpenAI's Assistants API. It offers a visual builder for easy assistant configuration, suitable for both technical and non-technical users.",
    },
    {
      question: 'What do I need to get started with Qflit?',
      answer:
        "To use Qflit, you'll need an OpenAI account with a positive balance and an OpenAI API key. You'll also need Qflit API credits, but new users get 150 free credits to start with.",
    },
    {
      question:
        'Are there any free credits or trials available?',
      answer:
        'Yes! New users get 150 free API credits on Qflit to help you get started. For WhatsApp integration, you get 1,000 free conversations through the WhatsApp Cloud API. This means you can set up and have your first 1,000 WhatsApp conversations while only paying for OpenAI usage and any additional Qflit credits beyond the free 150.',
    },
    {
      question: 'How does pricing work for Qflit?',
      answer:
        'Qflit operates on a credit system. After using your 150 free credits, you can purchase additional API credits which are used as you make API call requests to our messaging endpoint or receive messages on WhatsApp. This allows for flexible usage based on your needs.',
    },

    {
      question:
        'What are the integration costs for WhatsApp?',
      answer:
        "Setting up a Qflit AI chatbot with WhatsApp integration involves three separate services:<br><br><b>1. OpenAI:</b> Requires an account with a positive balance. You pay for API usage based on your chatbot's activity<br><b>2. Qflit:</b> Offers 150 free API credits for new users. Additional credits can be purchased as needed. A one-time WhatsApp activation fee is required to allow anyone to message your AI assistant on WhatsApp, this comes with 30,000 incoming message credits.</b><br><b>3. WhatsApp Cloud API</b>: Provides 1,000 free conversations per month. Additional conversations are billed separately by Meta.",
    },
    {
      question:
        'Can non-technical users build AI assistants with Qflit?',
      answer:
        "Absolutely! Qflit's intuitive visual interface allows anyone to build sophisticated AI assistants without coding knowledge. You can easily configure your assistant's behavior, responses, and integrations through a user-friendly dashboard.",
    },
    {
      question: "What is Qflit's API designer?",
      answer:
        "Qflit's API designer is a visual tool that allows users to link their AI assistant's functions to external systems. You can design and configure API calls to your own backend services or third-party APIs, extending your assistant's capabilities without complex coding.",
    },
    {
      question:
        'How does Qflit handle real-time communication?',
      answer:
        'Qflit offers WebSocket and webhook delivery in real-time. All responses and events from the AI assistant are automatically sent to your WebSockets or webhooks, ensuring seamless, real-time interactions without complex setup.',
    },
  ];

  let activeIndex = null;

  function toggleItem(index) {
    activeIndex = activeIndex === index ? null : index;
  }
</script>

<div class="parent">
  <h1>Frequently Asked Questions</h1>
  <div class="faq-container">
    {#each faqs as faq, index}
      <div class="faq-item">
        <button
          class="faq-question"
          class:active="{activeIndex === index}"
          on:click="{() => toggleItem(index)}"
        >
          {faq.question}
          <span class="icon"
            >{activeIndex === index ? '−' : '+'}</span
          >
        </button>
        {#if activeIndex === index}
          <div
            class="faq-answer"
            transition:slide="{{
              duration: 300,
              easing: quintOut,
            }}"
          >
            {@html faq.answer}
          </div>
        {/if}
      </div>
    {/each}
  </div>
</div>

<style>
  .faq-container {
    max-width: 900px;
    margin: 3rem auto;
    border-radius: 12px;
    overflow: hidden;
  }

  .parent {
    width: calc(100vw - 20px);
    max-width: 1200px;
    margin: auto;
    border-radius: 16px;
    background-color: rgba(249, 255, 239, 0.511);
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 3rem;
  }

  @media (max-width: 768px) {
    .parent {
      margin-top: 36px;
      padding: 1rem;
      background-color: rgba(249, 255, 254, 0.605);
    }
    h1 {
      font-size: 24px;
    }
  }

  h1 {
    align-self: center;
    text-align: center;
  }

  .faq-item:last-child {
    border-bottom: none;
  }

  .faq-question {
    width: 100%;
    text-align: left;
    padding: 1.25rem 1.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
  }

  .faq-question:hover {
    background-color: #f9f9f9;
  }

  button {
    margin-bottom: 0px;
  }
  .faq-question.active {
    color: #096700;
  }

  .icon {
    font-size: 1.25rem;
    line-height: 1;
    transition: transform 0.3s ease;
  }

  .faq-question.active .icon {
    transform: rotate(45deg);
  }

  .faq-answer {
    padding: 0 1.5rem 1.25rem;
    line-height: 1.6;
    color: #555;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
</style>
