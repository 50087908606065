<script>
  export let label = 'Button'; // Button label
  export let backgroundColor = '#007bff'; // Default background color
  export let textColor = '#ffffff'; // Default text color
  export let horizontalPadding = '16px'; // Horizontal padding
  export let verticalPadding = '8px'; // Vertical padding
  export let icon = null; // Icon component or HTML element
  export let onClick = () => {}; // Click event handler

  let isPressed = false;

  function handlePress() {
    isPressed = true;
  }

  function handleRelease() {
    isPressed = false;
  }
</script>

<div
  class="btn"
  class:isPressed
  on:touchstart="{handlePress}"
  on:touchend="{() => {
    handleRelease();
    onClick();
  }}"
  on:mousedown="{handlePress}"
  on:mouseup="{() => {
    handleRelease();
    onClick();
  }}"
  style="
    background-color: {icon
    ? 'transparent'
    : backgroundColor};
    color: {textColor};
    padding: {icon
    ? '0'
    : verticalPadding + ' ' + horizontalPadding};
  "
>
  {#if icon}
    <span class="icon">{@html icon}</span>
  {:else}
    {label}
  {/if}
</div>

<style>
  .btn {
    outline: none;
    border-radius: 48px;
    position: relative;
    cursor: pointer;
    transition:
      transform 0.2s ease,
      all 0.5s ease;
    margin-left: 8px;
    user-select: none; /* Prevent text selection */
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .isPressed {
    transform: scale(0.75);
    opacity: 0.5;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    padding: 4px;
    padding-left: 0px;
    font-size: 24px; /* Adjust icon size as needed */
  }
</style>
