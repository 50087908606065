<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="24"
  height="24"
  color="#ff006c"
  fill="none"
>
  <circle
    cx="17"
    cy="17"
    r="5"
    stroke="currentColor"
    stroke-width="1.5"
  ></circle>
  <path
    d="M7.5 13H6.4C4.32582 13 3.28873 13 2.64437 12.3556C2 11.7113 2 10.6742 2 8.6V6.4C2 4.32582 2 3.28873 2.64437 2.64437C3.28873 2 4.32582 2 6.4 2H8.6C10.6742 2 11.7113 2 12.3556 2.64437C13 3.28873 13 4.32582 13 6.4V7.5"
    stroke="currentColor"
    stroke-width="1.5"
  ></path>
  <path
    d="M12.0348 19C9.17734 18.5206 7 16.0355 7 13.0418C7 9.70499 9.70499 7 13.0418 7C16.0355 7 18.5206 9.17734 19 12.0348"
    stroke="currentColor"
    stroke-width="1.5"
  ></path>
</svg>
