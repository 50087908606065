<script>
  function openDocs() {
    window.location.href = 'https://docs.qflit.com';
  }
  function openDashboard() {
    window.location.href = 'https://app.qflit.com/register';
  }
</script>

<div class="cta-container">
  <h1>Start Building Smarter Today</h1>
  <p>
    Empower your business or development process with AI —
    without the complexity. Sign up and see how easy it is
    to bring your assistant to life.
  </p>
  <div class="ctas">
    <div class="row-container">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        on:click="{openDashboard}"
        class="button btn-signup"
      >
        <p>Get Started</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          color="#ffffff"
          fill="none"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="1.5"
          ></circle>
          <path
            d="M10.5 8C10.5 8 13.5 10.946 13.5 12C13.5 13.0541 10.5 16 10.5 16"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        on:click="{openDocs}"
        class="button btn-read-docs"
      >
        <p>Read Docs</p>
      </div>
    </div>
  </div>
</div>

<style>
  h1 {
    font-size: 48px;
    max-width: 720px;
    margin: 0px;
    margin-top: 16px;
    margin-bottom: 8px;
    text-align: center;
  }

  .ctas {
    align-self: center;
    display: flex;
    flex-direction: column;
  }

  .row-container {
    margin-top: 16px;
    align-items: center;
    display: flex;
  }
  .btn-signup {
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
    background-color: #002a14;
    color: #fff0f0;
    text-align: center;
    align-items: center;
    display: flex;
    column-gap: 8px;
  }

  .btn-read-docs {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #00464b;
  }

  p {
    text-align: center;
    align-self: center;
    margin: 0px;
    font-size: 19px;
    max-width: 720px;
  }

  .cta-container {
    z-index: 3;
    margin: auto;
    border-radius: 16px;
    width: calc(100% - 20px);
    max-width: 1200px;
    background-color: rgb(239, 255, 242);
    padding: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .cta-container {
      background-color: white;
      padding-bottom: 0px;
      margin-bottom: 0px;
      padding: 1.5rem;
    }

    h1 {
      font-size: 36px;
      max-width: 280px;
    }

    p {
      font-size: 16px;
    }
  }
</style>
