<script>
  export let logoLong = '../assets/images/logo.png';
  export let facebooklogo =
    '../assets/images/home/facebook.png';
  const facebookLink = 'https://www.facebook.com/profile.php?id=61567491451137';

  const youtubeLink =
    'https://www.youtube.com/channel/UCkcqvKxzddD-Tk9GrOYZMQA';
  const email = 'hello@qflit.com';
  const phone = '+44 7774 823767'; // Example phone number
</script>

<footer class="footer">
  <div class="footer-container">
    <!-- Logo and Copyright -->
    <div class="footer-item logo-section">
      <img src="{logoLong}" alt="Qflit Logo" class="logo" />
      <p>© 2024 Qflit AI. <br />All rights reserved.</p>
    </div>

    <!-- Social Media Links -->
    <div class="footer-item social-media-section">
      <p>Follow us on social media</p>
      <div class="social-icons">
        <a
          href="{facebookLink}"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="icon-container">
            <img
              src="{facebooklogo}"
              alt="logo fb"
              style="height: 25px; width:25px; object-fit:contain;"
            />
          </div>
        </a>
        <a
          href="{youtubeLink}"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="icon-container">
            <svg
              height="25px"
              width="25px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 461.001 461.001"
              xml:space="preserve"
            >
              <g>
                <path
                  style="fill:#F61C0D;"
                  d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
		c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
		C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
		c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                ></path>
              </g>
            </svg>
          </div>
        </a>
      </div>
    </div>

    <!-- Contact Information -->
    <div class="footer-item contact-section">
      <p>Get in touch</p>
      <div class="contact-info">
        <div class="contact-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="#ffffff"
            fill="none"
          >
            <path
              d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linejoin="round"
            ></path>
            <path
              d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linejoin="round"
            ></path>
          </svg>
          <a href="mailto:{email}">{email}</a>
        </div>
        <div class="contact-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="#ffffff"
            fill="none"
          >
            <path
              d="M13 3C17.4183 3 21 6.58172 21 11M13.5 6.5C15.7091 6.5 17.5 8.29086 17.5 10.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M9.15825 5.71223L8.7556 4.80625C8.49232 4.21388 8.36068 3.91768 8.1638 3.69101C7.91707 3.40694 7.59547 3.19794 7.23567 3.08785C6.94858 3 6.62446 3 5.97621 3C5.02791 3 4.55375 3 4.15573 3.18229C3.68687 3.39702 3.26343 3.86328 3.09473 4.3506C2.95151 4.76429 2.99253 5.18943 3.07458 6.0397C3.94791 15.0902 8.90981 20.0521 17.9603 20.9254C18.8106 21.0075 19.2357 21.0485 19.6494 20.9053C20.1367 20.7366 20.603 20.3131 20.8177 19.8443C21 19.4462 21 18.9721 21 18.0238C21 17.3755 21 17.0514 20.9122 16.7643C20.8021 16.4045 20.5931 16.0829 20.309 15.8362C20.0823 15.6393 19.7861 15.5077 19.1937 15.2444L18.2878 14.8417C17.6462 14.5566 17.3255 14.4141 16.9995 14.3831C16.6876 14.3534 16.3731 14.3972 16.0811 14.5109C15.776 14.6297 15.5063 14.8544 14.967 15.3038C14.4301 15.7512 14.1617 15.9749 13.8337 16.0947C13.543 16.2009 13.1586 16.2403 12.8523 16.1951C12.5069 16.1442 12.2423 16.0029 11.7133 15.7201C10.0672 14.8405 9.15953 13.9328 8.27986 12.2867C7.99714 11.7577 7.85578 11.4931 7.80487 11.1477C7.75974 10.8414 7.79908 10.457 7.9053 10.1663C8.02512 9.83828 8.24881 9.56986 8.69619 9.033C9.14562 8.49368 9.37034 8.22402 9.48915 7.91891C9.60285 7.62694 9.64661 7.3124 9.61694 7.00048C9.58594 6.67452 9.44338 6.35376 9.15825 5.71223Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
            ></path>
          </svg>
          <span>{phone}</span>
        </div>
      </div>
    </div>
  </div>
</footer>

<style>
  footer {
    background-color: #032820;
    color: white;
    padding: 20px;
    margin-top: 64px;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
  }

  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
  }

  .logo {
    width: 45px;
    height: auto;
    object-fit: contain;
  }

  .social-icons {
    display: flex; /* Align icons in a row */
    justify-content: center; /* Center the icons */
    margin-top: 5px; /* Space between text and icons */
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(239, 255, 241);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px; /* Adjust horizontal space between icons */
  }

  .icon-container img {
    width: 20px; /* Adjust icon size */
    height: 20px; /* Adjust icon size */
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align contact details to start */
    margin-top: 5px; /* Space between text and details */
  }

  .contact-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 5px 0; /* Space between contact items */
  }

  .contact-icon {
    margin-right: 8px;
    width: 20px; /* Adjust icon size */
    height: 20px; /* Adjust icon size */
  }

  a {
    color: white;
  }

  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }

    .contact-info {
      align-items: center; /* Center contact info on mobile */
    }
  }
</style>
