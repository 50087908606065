<script>
  import { onMount, onDestroy } from 'svelte';
  import Button from './Button.svelte';

  let isMenuOpen = false;

  export let logoLong = '../assets/images/logo.png';

  function toggleMenu() {
    isMenuOpen = !isMenuOpen;
  }

  function openDashboard() {
    window.location.href = 'https://app.qflit.com';
  }

  function openDocs() {
    window.location.href = 'https://docs.qflit.com';
  }
  let hasBoxShadow = false;
  const scrollThreshold = 80;

  // Handle scrolling to add/remove box-shadow
  function handleScroll() {
    hasBoxShadow = window.scrollY > scrollThreshold;
  }

  // Attach and remove scroll event listener
  onMount(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component destroy
    onDestroy(() => {
      window.removeEventListener('scroll', handleScroll);
    });
  });

  const menuIcon = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="white"
      fill="none"
    >
      <path
        d="M4 5L20 5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M4 12L20 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M4 19L20 19"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  `;

  const closeIcon = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="white"
      fill="none"
    >
      <path
        d="M6 18L18 6"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M6 6L18 18"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  `;

  function scrollToElementById(elementId) {
    toggleMenu();
    const element = document.getElementById(elementId);

    setTimeout(() => {
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top +
          window.scrollY;
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });
      } else {
        console.error(
          `Element with ID "${elementId}" not found.`,
        );
      }
    }, 100);
  }
</script>

<nav
  class="navbar {hasBoxShadow
    ? 'box-shadow'
    : 'not-box-shadow'}"
>
  <div class="row-container">
    <div class="menu-toggle">
      <Button
        icon="{menuIcon}"
        textColor="#fff"
        onClick="{toggleMenu}"
      />
    </div>
    <a style="text-decoration: none;" href="#hero">
      <div class="logo">
        <div class="logo-img-container">
          <img
            class="logo-img"
            src="{logoLong}"
            alt="Logo"
          />
        </div>
        <p class="logo-text">
          Qflit <span class="qflit-text">AI</span>
        </p>
      </div>
    </a>
  </div>

  <!-- Desktop menu -->
  <ul class="desktop-menu">
    <li>
      <a data-target="#hero" href="#hero">Home</a>
    </li>
    <li>
      <a href="#how-it-works">Why Qflit</a>
    </li>
    <li>
      <a href="#use-cases">Use Cases</a>
    </li>
    <li><a href="#whatsapp">WhatsApp</a></li>
    <li><a href="#pricing">Pricing</a></li>
    <li><a href="#contact">Contact</a></li>
  </ul>

  <!-- Mobile menu -->
  <div class="mobile-menu" class:isMenuOpen>
    <div class="mobile-menu-header">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="close-icon" on:click="{toggleMenu}">
        {@html closeIcon}
      </span>
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <ul on:click="{toggleMenu}">
      <li>
        <a data-target="#hero" href="#hero">Home</a>
      </li>
      <li>
        <a href="#use-cases">Why Qflit</a>
      </li>
      <li><a href="#whatsapp">WhatsApp</a></li>
      <li><a href="#pricing">Pricing</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
  </div>

  <div class="row-container">
    <Button
      onClick="{() => openDashboard()}"
      verticalPadding="14px"
      label="Dashboard"
      backgroundColor="#002515"
    ></Button>
    <div class="docs">
      <Button
        verticalPadding="14px"
        onClick="{() => openDocs()}"
        label="Docs"
        backgroundColor="rgb(0, 0, 0, 0)"
      ></Button>
    </div>
  </div>
</nav>

<style>
  .box-shadow {
    box-shadow: 5px 5px 24px 1px rgba(103, 103, 103, 0.1);
    background-color: #09271b;
    color: rgb(255, 255, 255);
  }

  .not-box-shadow {
    background-color: #00170e;
    color: white;
  }

  .navbar {
    transition: all ease 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
  }

  .qflit-text {
    color: #00ff82;
  }

  .logo-text {
    font-weight: bold;
    font-size: 20px;
    color: #c8f9ff;
  }

  .menu-button {
    cursor: pointer;
    border-radius: 32px;
    position: relative;
    color: white;
  }

  .logo-img {
    height: 24px;
    width: auto;
    object-fit: contain;
  }

  .logo-img-container {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-color: black;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .desktop-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    margin: 0 auto; /* Center horizontally */
  }

  .desktop-menu li {
    margin-left: 0;
  }

  .desktop-menu a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #00170e;
    height: 100vh;
    display: none;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    z-index: 5;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu.isMenuOpen {
    display: flex;
    transform: translateY(0);
  }

  .mobile-menu-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }

  .close-icon {
    cursor: pointer;
    padding: 0.5rem;
  }

  .mobile-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .mobile-menu li {
    margin: 1rem 0;
  }

  .mobile-menu a {
    color: white;
    text-decoration: none;
    font-size: 22px;
    transition: color 0.2s;
  }

  .mobile-menu a:hover {
    color: #00ff82;
  }

  .menu-toggle {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop-menu {
      display: none;
    }

    .menu-toggle {
      display: inline-flex;
    }

    .mobile-menu {
      display: none;
    }

    .mobile-menu.isMenuOpen {
      display: flex;
    }

    .navbar {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-left: 16px;
      padding-right: 16px;
    }

    .logo-text {
      color: #dae5e0;
      font-size: 18px;
    }

    .logo-img {
      height: 20px;
    }

    .logo-img-container {
      height: 36px;
      width: 36px;
    }

    .button p {
      font-size: 14px;
    }

    .docs {
      display: none;
    }
  }
</style>
