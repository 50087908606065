<script>
  import CTA from './CTA.svelte';
  export let bgpattern =
    '../assets/images/home/community-img.webp';

  let activeTab = 'custom-assistants';

  const selectTab = (tab) => {
    activeTab = tab;
  };

  let tabs = [
    {
      title: 'Custom Assistants',
      id: 'custom-assistants',
    },
    {
      title: 'WhatsApp',
      id: 'whatsapp',
    },
  ];
</script>

<div id="pricing"></div>
<div class="pricing-container">
  <div>
    <p class="label">
      Do <span style="color:#00B741">more</span>, for
      <span style="color:#00B741">less</span>
    </p>
    <h1>Flexible pricing to suit every need</h1>
  </div>
  <div class="tabs">
    {#each tabs as { title, id }, index}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="tab {activeTab === id ? 'active' : ''}"
        on:click="{() => selectTab(id)}"
      >
        <p>{title}</p>
      </div>
    {/each}
  </div>

  <p class="intro-txt">
    {#if activeTab === 'custom-assistants'}
      With Qflit, setting up and testing AI assistants from
      the dashboard is free. Charges only apply for API
      calls made to the messaging endpoint. New accounts get <i
        >150 free API calls</i
      >. For ongoing usage, refer to our top-up options
      below.{/if}

    {#if activeTab === 'whatsapp'}
      Create and test your assistants for free. A <i
        >$99.00</i
      >
      one-time activation fee allows anyone to chat with your
      AI assistant on WhatsApp, and this comes with
      <i>30k</i> incoming message credits. For ongoing usage,
      see the top-up options below.
    {/if}
  </p>

  <div class="pricing-row">
    <div class="pricing launch">
      <div
        style="display: flex; gap:8px; align-items:center;"
      >
        <p>
          <b
            >30k {#if activeTab !== 'whatsapp'}
              API Calls{/if}{#if activeTab === 'whatsapp'}
              Incoming Messages{/if}</b
          >
        </p>
      </div>
      <div class="highlight">
        <h3>$99.00</h3>
      </div>
      <p style="font-weight: 300;">
        {#if activeTab !== 'whatsapp'}
          This purchase provides ample API calls to power
          your AI assistant for a wide range of use cases.{/if}{#if activeTab === 'whatsapp'}
          This top-up enables your AI assistant to handle a
          large number of incoming WhatsApp messages,
          ensuring uninterrupted communication.
        {/if}
      </p>
    </div>
    <div class="pricing scale">
      <div
        style="display: flex; gap:8px; align-items:center;"
      >
        <h2>
          100k {#if activeTab !== 'whatsapp'}
            API Calls{/if}{#if activeTab === 'whatsapp'}
            Incoming Messages{/if}
        </h2>
      </div>

      <div class="highlight">
        <h3>$159.00</h3>
      </div>

      <p style="font-weight: 300;">
        {#if activeTab !== 'whatsapp'}
          Designed for businesses with higher demands,
          offering extensive API call capacity.
        {/if}{#if activeTab === 'whatsapp'}
          Designed for high-demand scenarios, this top-up
          equips your AI assistant to handle an extensive
          number of incoming WhatsApp messages.
        {/if}
      </p>
    </div>
  </div>
</div>

<style>
  .label {
    color: #0a5f3a;
    font-size: 18px;
    margin: 0;
  }

  .intro-txt {
    max-width: 780px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 21px;
    font-weight: 300;
  }

  h1 {
    font-size: 48px;
    max-width: 400px;
    margin-top: 16px;
    text-align: center;
  }

  .pricing-row {
    display: flex;
    gap: 16px;
    max-width: 800px;
  }

  h2 {
    margin: 0px;
    font-size: 20px;
  }

  .highlight {
    background-color: #d7fff6;
    color: #43675d;
    border-radius: 32px;
    padding: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    align-self: flex-start;
    align-items: center;
  }

  .pricing {
    padding: 24px;
    border-radius: 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
    margin-top: 24px;
  }

  h3 {
    margin: 0px;
  }

  .launch {
    background-color: #001d1c;
    align-self: stretch;
  }

  .scale {
    background-color: #aaffec;
    color: rgb(0, 0, 0);
  }

  .tab {
    padding: 16px;
    cursor: pointer;
    transition: all 0.5s ease;
    border-radius: 32px;
    color: rgb(228, 255, 226);
  }
  .tabs {
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 32px;
  }

  .tab:hover {
    color: rgb(36, 133, 83);
  }

  .tab.active {
    background-color: #e2f9ff;
    color: #003e12;
  }

  .pricing-container {
    width: calc(100% - 20px);
    max-width: 1200px;
    margin: auto;
    overflow-x: hidden;
    z-index: 3;
    color: white;
    padding: 3rem;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    margin-top: 4rem;
    text-align: center;
    background-color: #00201f;
    position: relative;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    margin: 0px;
  }

  h3 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    .pricing-container {
      padding: 1.5rem;
      margin-bottom: 1rem;
      align-items: flex-start;
    }

    .pricing-row {
      gap: 8px;
      flex-direction: column;
    }

    .intro-txt {
      font-size: 16px;
      text-align: left;
    }

    .tabs {
      padding: 0px;
    }
    .highlight {
      padding: 8px;
    }
    .highlight h3 {
      font-size: 15px;
      margin: 0px;
    }

    .tab {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .tab p {
      font-size: 14px;
    }

    .pricing {
      margin-top: 8px;
    }

    p {
      font-size: 15px;
      text-align: left;
    }

    h1 {
      text-align: left;
      font-size: 24px;
      max-width: 280px;
    }
  }
</style>
