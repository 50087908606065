<script>
  import Button from './Button.svelte';

  export let dashboard =
    '../assets/images/home/dashboard.webp';

  function openDocs() {
    window.location.href = 'https://docs.qflit.com';
  }
  function openDashboard() {
    window.location.href = 'https://app.qflit.com/register';
  }
</script>

<section id="hero" class="hero">
  <svg
    class="shape-1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid meet"
  >
    <!-- Define a gradient for the fading effect -->
    <defs>
      <linearGradient
        id="fade"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          style="stop-color:white;stop-opacity:1"
        ></stop>
        <stop
          offset="100%"
          style="stop-color:white;stop-opacity:0"
        ></stop>
      </linearGradient>
    </defs>

    <!-- Draw a shape with a horizontal top line, then a fading 45-degree inward diagonal line -->
    <path
      d="M 20 20 L 80 20 L 40 80"
      stroke="url(#fade)"
      stroke-width="1"
      fill="none"
    ></path>
  </svg>
  <svg
    class="shape-1-2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid meet"
  >
    <!-- Define a gradient for the fading effect -->
    <defs>
      <linearGradient
        id="fade"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          style="stop-color:white;stop-opacity:1"
        ></stop>
        <stop
          offset="100%"
          style="stop-color:white;stop-opacity:0"
        ></stop>
      </linearGradient>
    </defs>

    <!-- Draw a shape with a horizontal top line, then a fading 45-degree inward diagonal line -->
    <path
      d="M 20 20 L 80 20 L 40 80"
      stroke="url(#fade)"
      stroke-width="1"
      fill="none"
    ></path>
  </svg>
  <svg
    class="shape-2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid meet"
  >
    <!-- Define a gradient for the fading effect -->
    <defs>
      <linearGradient
        id="fade"
        x1="100%"
        y1="0%"
        x2="0%"
        y2="0%"
      >
        <stop
          offset="0%"
          style="stop-color:gray;stop-opacity:1"
        ></stop>
        <stop
          offset="100%"
          style="stop-color:gray;stop-opacity:0"
        ></stop>
      </linearGradient>
    </defs>

    <!-- Draw a mirrored '7' shape (flipped horizontally) with a fading diagonal line -->
    <path
      d="M 80 20 L 20 20 L 60 80"
      stroke="url(#fade)"
      stroke-width="1"
      fill="none"
    ></path>
  </svg>
  <div class="shape-pink">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="18"
      height="18"
      color="#00C400"
      fill="none"
    >
      <circle
        cx="17"
        cy="17"
        r="5"
        stroke="currentColor"
        stroke-width="1.5"
      ></circle>
      <path
        d="M7.5 13H6.4C4.32582 13 3.28873 13 2.64437 12.3556C2 11.7113 2 10.6742 2 8.6V6.4C2 4.32582 2 3.28873 2.64437 2.64437C3.28873 2 4.32582 2 6.4 2H8.6C10.6742 2 11.7113 2 12.3556 2.64437C13 3.28873 13 4.32582 13 6.4V7.5"
        stroke="currentColor"
        stroke-width="1.5"
      ></path>
      <path
        d="M12.0348 19C9.17734 18.5206 7 16.0355 7 13.0418C7 9.70499 9.70499 7 13.0418 7C16.0355 7 18.5206 9.17734 19 12.0348"
        stroke="currentColor"
        stroke-width="1.5"
      ></path>
    </svg>
  </div>

  <div class="vector-img">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 200 200"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.dev/svgjs"
      ><path
        fill="rgba(114, 255, 97, 1)"
        fill-rule="evenodd"
        d="M199.06 140.014c.545 5.496-3.985 9.986-9.508 9.986h-36.25c-5.523 0-9.882-4.537-11.129-9.917-4.492-19.385-21.869-33.833-42.62-33.833-20.752 0-38.13 14.448-42.622 33.833-1.247 5.38-5.606 9.917-11.129 9.917H9.552C4.03 150-.5 145.51.045 140.014 5.055 89.474 47.694 50 99.552 50c51.858 0 94.497 39.474 99.508 90.014Z"
        clip-rule="evenodd"
      ></path></svg
    >
  </div>

  <h1>
    AI assistants <span>simplified</span> for you
  </h1>
  <div class="subtitle">
    <p>
      <span class="whatsapp">Build⚡️</span> AI apps for
      workflow automation, customer support, and
      personalized experiences like conversational shopping
      on
      <span class="whatsapp"
        >WhatsApp <svg
          fill="#0dbf22"
          class="whatsapp-icon"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 308 308"
          xml:space="preserve"
          stroke="#0dbf22"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>

          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>

          <g id="SVGRepo_iconCarrier">
            <g id="XMLID_468_">
              <path
                id="XMLID_469_"
                d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"
              ></path>
              <path
                id="XMLID_470_"
                d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"
              ></path>
            </g>
          </g>
        </svg></span
      > . Qflit streamlines development, leveraging the capabilities
      of OpenAI's Assistants API. Initiate conversations via
      our messaging endpoint, and receive real-time responses
      through webhooks and WebSockets.
    </p>
  </div>
  <div class="subtitle-mobile">
    <p>
      Build fully managed conversational apps powered by the
      OpenAI Assistants API. Automate workflows, and deliver
      personalized experiences with ease.
    </p>
  </div>
  <div class="ctas">
    <div class="row-container">
      <Button
        onClick="{() => openDashboard()}"
        verticalPadding="20px"
        label="Sign Up"
        horizontalPadding="24px"
        textColor="white"
        backgroundColor="#00aa4f"
      ></Button>

      <Button
        onClick="{() => openDocs()}"
        verticalPadding="20px"
        label="Read Docs"
        horizontalPadding="16px"
        textColor="#d6fcff"
        backgroundColor="transparent"
      ></Button>
    </div>
  </div>
</section>

<div class="wave">
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="transform:rotate(180deg); transition: 0.3s"
    viewBox="0 0 1440 310"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    ><defs
      ><linearGradient
        id="sw-gradient-0"
        x1="0"
        x2="0"
        y1="1"
        y2="0"
        ><stop stop-color="#00170e" offset="0%"></stop><stop
          stop-color="#00170e"
          offset="100%"
        ></stop></linearGradient
      ></defs
    ><path
      style="transform:translate(0, 0px); opacity:1"
      fill="url(#sw-gradient-0)"
      d="M0,155L120,134.3C240,114,480,72,720,46.5C960,21,1200,10,1440,41.3C1680,72,1920,145,2160,165.3C2400,186,2640,155,2880,134.3C3120,114,3360,103,3600,87.8C3840,72,4080,52,4320,77.5C4560,103,4800,176,5040,175.7C5280,176,5520,103,5760,77.5C6000,52,6240,72,6480,72.3C6720,72,6960,52,7200,62C7440,72,7680,114,7920,134.3C8160,155,8400,155,8640,170.5C8880,186,9120,217,9360,211.8C9600,207,9840,165,10080,144.7C10320,124,10560,124,10800,108.5C11040,93,11280,62,11520,67.2C11760,72,12000,114,12240,144.7C12480,176,12720,196,12960,191.2C13200,186,13440,155,13680,139.5C13920,124,14160,124,14400,134.3C14640,145,14880,165,15120,170.5C15360,176,15600,165,15840,155C16080,145,16320,134,16560,113.7C16800,93,17040,62,17160,46.5L17280,31L17280,310L17160,310C17040,310,16800,310,16560,310C16320,310,16080,310,15840,310C15600,310,15360,310,15120,310C14880,310,14640,310,14400,310C14160,310,13920,310,13680,310C13440,310,13200,310,12960,310C12720,310,12480,310,12240,310C12000,310,11760,310,11520,310C11280,310,11040,310,10800,310C10560,310,10320,310,10080,310C9840,310,9600,310,9360,310C9120,310,8880,310,8640,310C8400,310,8160,310,7920,310C7680,310,7440,310,7200,310C6960,310,6720,310,6480,310C6240,310,6000,310,5760,310C5520,310,5280,310,5040,310C4800,310,4560,310,4320,310C4080,310,3840,310,3600,310C3360,310,3120,310,2880,310C2640,310,2400,310,2160,310C1920,310,1680,310,1440,310C1200,310,960,310,720,310C480,310,240,310,120,310L0,310Z"
    ></path></svg
  >
</div>

<div class="dashboard">
  <img class="dash-img" src="{dashboard}" alt="dashboard" />
</div>

<style>
  .hero {
    text-align: center;
    padding: 3rem 1rem;
    padding-bottom: 220px;
    flex-direction: column;
    align-items: center;
    display: flex;
    background-color: #00170e;
    position: relative;
    color: white;
    max-width: 100%;
    overflow-x: hidden;
  }
  .wave {
    position: relative;
    z-index: 1;
    margin-top: -10px;
  }

  .shape-pink {
    display: none;
  }
  .vector-img {
    display: none;
  }

  .dashboard {
    margin-top: -470px;
    display: flex;
    justify-content: center;
    align-self: center;
    flex: 1;
    z-index: 2;
    position: relative;
  }
  .dash-img {
    height: 'auto';
    box-sizing: border-box;
    width: calc(100% - 20px);
    max-width: 1200px;
    border-radius: 16px;
    background-color: white;
    padding: 8px;
    box-shadow: 5px 5px 24px 1px rgba(0, 134, 211, 0.04);
  }
  .whatsapp-icon {
    height: 16px;
    width: 16px;
  }
  .whatsapp {
    font-size: 15px;
    background-color: #d7fff6;
    color: #43675d;
    border-radius: 32px;
    padding: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    align-items: center;
  }

  .ctas {
    align-self: center;
    display: flex;
    flex-direction: column;
  }

  .subtitle {
    max-width: 1000px;
    font-size: 24px;
  }

  h1 {
    font-size: 6rem;
    margin-top: 8rem;
    margin-bottom: 1rem;
    max-width: 920px;
    text-align: center;
    align-self: center;
  }

  .btn-signup {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #00aa4f;
    color: #fff0f0;
    text-align: center;
    align-items: center;
    display: flex;
    column-gap: 8px;
  }

  .shape-1 {
    height: 400px;
    width: 400px;
    position: absolute;
    top: 100px;
    left: -200px;
    opacity: 0.06;
  }

  .shape-1-2 {
    height: 400px;
    width: 400px;
    position: absolute;
    top: 180px;
    left: -150px;
    opacity: 0.06;
  }

  .shape-2 {
    height: 400px;
    width: 400px;
    position: absolute;
    top: 175px;
    right: -150px;
    opacity: 0.06;
  }
  .subtitle-mobile {
    display: none;
  }

  .btn-read-docs {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #d6fcff;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 2.3rem;
      margin-top: 72px;
      max-width: 360px;
      color: rgb(247, 253, 249);
      z-index: 1;
    }

    .shape-pink {
      display: flex;
      position: absolute;
      top: 380px;
      left: 30px;
    }

    .vector-img {
      display: flex;
      position: absolute;
      top: 350px;
      right: 30px;
      z-index: 0;
    }

    .btn-read-docs {
      color: #c0ffed;
    }

    .btn-signup {
      background-color: #00a548;
    }

    .button p {
      font-size: 14px;
    }
    .button {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    .subtitle {
      font-size: 20px;
      display: none;
    }

    .shape-1 {
      height: 100px;
      width: 500px;
      position: absolute;
      top: 100px;
      left: -220px;
      opacity: 0.06;
    }

    .shape-1-2 {
      height: 100px;
      width: 400px;
      position: absolute;
      top: 120px;
      left: -200px;
      opacity: 0.06;
    }

    .shape-2 {
      height: 100px;
      width: 400px;
      position: absolute;
      top: 185px;
      right: -180px;
      opacity: 0.06;
    }

    .subtitle-mobile {
      display: flex;
      font-size: 18px;
    }

    .subtitle-mobile p {
      margin-top: 8px;
    }

    .whatsapp {
      font-size: 15px;
      background-color: #d7fff6;
      color: #43675d;
      border-radius: 32px;
      padding: 4px;
      align-items: center;
    }
    .hero {
      padding-bottom: 130px;
      max-width: 100%;
      position: relative;
    }

    .wave {
      max-width: 100%;
      overflow-x: hidden;
    }
    .whatsapp-icon {
      height: 12px;
      width: 12px;
    }
    .dash-img {
      height: 'auto';
      width: calc(100% - 40px);
      border-radius: 16px;
      background-color: white;
      box-shadow: 5px 5px 24px 1px
        rgba(124, 255, 207, 0.102);
    }

    .dashboard {
      margin-top: -180px;
      padding-bottom: 24px;
    }
  }

  @media (min-width: 601px) and (max-width: 1000px) {
    h1 {
      font-size: 5rem;
      margin-top: 106px;
      max-width: 820px;
    }
    .subtitle {
      font-size: 24px;
      font-weight: 400;
    }

    .whatsapp {
      font-size: 15px;
      background-color: #d7fff6;
      color: #43675d;
      border-radius: 32px;
      padding: 4px;
      align-items: center;
    }
    .hero {
      padding-bottom: 130px;
    }
    .whatsapp-icon {
      height: 12px;
      width: 12px;
    }
    .dash-img {
      height: 'auto';
      width: calc(100% - 40px);
      border-radius: 16px;
      background-color: white;
    }

    .dashboard {
      margin-top: -180px;
    }
  }

  @media (min-width: 1001px) and (max-width: 1200px) {
    h1 {
      font-size: 5rem;
      margin-top: 106px;
      max-width: 820px;
    }
    .subtitle {
      font-size: 24px;
      font-weight: 400;
    }

    .shape-1 {
      display: none;
    }
    .shape-1-2 {
      display: none;
    }
    .shape-2 {
      display: none;
    }

    .whatsapp {
      font-size: 15px;
      background-color: #d7fff6;
      color: #43675d;
      border-radius: 32px;
      padding: 4px;
      align-items: center;
    }
    .hero {
      padding-bottom: 130px;
    }
    .whatsapp-icon {
      height: 12px;
      width: 12px;
    }
    .dash-img {
      height: 'auto';
      width: calc(100vw - 40px);
      border-radius: 16px;
      background-color: white;
    }

    .dashboard {
      margin-top: -290px;
    }
  }
</style>
